import React, { Component } from "react";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import validator from "validator";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import InputMask from "react-input-mask";
import SEO from "../../components/seo";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Navbar from "./components/layout/navbar";
import Header from "./components/layout/Header";
import SubHeader from "./components/layout/SubHeader";
import SubHeaderDesc from "./components/layout/SubHeaderDesc";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MY_API_KEY = process.env.GATSBY_GOOGLE_MAPS_KEY;

const filingReason = [
  { stageName: "New Business", internalName: "new_business" },
  { stageName: "Hired Employees", internalName: "hired_employees" },
  { stageName: "Banking", internalName: "banking" },
  {
    stageName: "Changed Organization Type",
    internalName: "change_organization"
  },
  {
    stageName: "Purchase Exisiting Business",
    internalName: "purchase_business"
  }
];

class einStart extends Component {
  state = {
    disableContinue: true,
    filingReason: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    ssn: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipcode: "",
    search: "",
    value: "",
    invalidAddress: ""
  };

  componentDidMount() {
    this.getStartStore();
  }

  getStartStore() {
    this.setState(
      {
        filingReason: Cookies.get("filingReason"),
        email: Cookies.get("email"),
        firstName: Cookies.get("firstName"),
        lastName: Cookies.get("lastName"),
        phone: Cookies.get("phone"),
        ssn: Cookies.get("ssn"),
        addressLine1: Cookies.get("addressLine1"),
        addressLine2: Cookies.get("addressLine2"),
        city: Cookies.get("city"),
        state: Cookies.get("state"),
        zipcode: Cookies.get("zipcode"),
        value: Cookies.get("value"),
        invalidAddress: Cookies.get("invalidAddress")
      },
      this.checkContinue
    );
  }

  checkContinue() {
    if (
      this.state.filingReason !== undefined &&
      this.state.filingReason !== "" &&
      validator.isEmail(String(this.state.email)) === true &&
      this.state.firstName !== undefined &&
      this.state.lastName !== undefined &&
      this.state.firstName !== "" &&
      this.state.lastName !== "" &&
      this.state.invalidAddress !== undefined &&
      this.state.invalidAddress === "false" &&
      this.state.phone !== undefined &&
      this.state.phone !== "" &&
      this.state.ssn !== undefined &&
      this.state.ssn !== ""
    ) {
      this.setState({ disableContinue: false });
    } else {
      this.setState({ disableContinue: true });
    }
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
    this.checkContinue();
  };

  onFilingReasonChange = (event, values) => {
    this.setState({ filingReason: values["internalName"] });
    this.checkContinue();
  };

  continue = e => {
    Cookies.set("filingReason", this.state.filingReason);
    Cookies.set("email", this.state.email);
    Cookies.set("firstName", this.state.firstName);
    Cookies.set("lastName", this.state.lastName);
    Cookies.set("phone", this.state.phone);
    Cookies.set("ssn", this.state.ssn);
    Cookies.set("addressLine1", this.state.addressLine1);
    Cookies.set("addressLine2", this.state.addressLine2);
    Cookies.set("city", this.state.city);
    Cookies.set("state", this.state.state);
    Cookies.set("zipcode", this.state.zipcode);
    Cookies.set("value", this.state.value);
    Cookies.set("invalidAddress", this.state.invalidAddress);
    navigate("/get-ein/entity-info");
  };

  handleInputChange = e => {
    this.setState({ search: e.target.value, value: e.target.value });
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    console.log(geocodedPrediction, originalPrediction); // eslint-disable-line
    if (geocodedPrediction["types"][0] === "premise") {
      this.setState(
        {
          search: "",
          value: geocodedPrediction.formatted_address,
          addressLine1:
            geocodedPrediction["address_components"][0]["long_name"] +
            " " +
            geocodedPrediction["address_components"][1]["long_name"],
          city: geocodedPrediction["address_components"][2]["long_name"],
          state: geocodedPrediction["address_components"][4]["short_name"],
          zipcode: geocodedPrediction["address_components"][6]["short_name"],
          invalidAddress: "false"
        },
        this.checkContinue
      );
    }
    if (geocodedPrediction["types"][0] === "street_address") {
      this.setState(
        {
          search: "",
          value: geocodedPrediction.formatted_address,
          addressLine1:
            geocodedPrediction["address_components"][0]["long_name"] +
            " " +
            geocodedPrediction["address_components"][1]["long_name"],
          city: geocodedPrediction["address_components"][2]["long_name"],
          state: geocodedPrediction["address_components"][4]["short_name"],
          zipcode: geocodedPrediction["address_components"][6]["short_name"],
          invalidAddress: "false"
        },
        this.checkContinue
      );
    }
  };

  handleNoResult = () => {
    //console.log("No results for ", this.state.search);
    this.setState(
      {
        invalidAddress: true
      },
      this.checkContinue
    );
  };

  handleStatusUpdate = status => {
    //console.log(status);
  };

  render() {
    const { search, value } = this.state;
    return (
      <div>
        <SEO title="Get an EIN" />
        <Navbar />
        <div style={{ margin: 20 }}>
          <Fade in={true} timeout={1200}>
            <Slide
              direction="up"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12}>
                  <Header>Filing Questions.</Header>
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Reason for Filing For an EIN</SubHeader>
                      </Grid>
                      <Grid item md={6}>
                        <Autocomplete
                          id="filing-reason"
                          options={filingReason}
                          getOptionLabel={option => option.stageName}
                          style={{ width: "300px" }}
                          onChange={this.onFilingReasonChange}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Filing Reason"
                              variant="outlined"
                              style={{ width: "300px" }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Email Address</SubHeader>
                        <SubHeaderDesc>
                          This email will be used for sending your Tax ID Number
                          and SS-4 Filed Form.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="email"
                          value={this.state.email}
                          label="Email"
                          type="text"
                          name="email"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <br />
                  <Grid spacing={2}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item md={6} style={{ paddingBottom: 0 }}>
                        <SubHeader>Personal Information</SubHeader>
                        <SubHeaderDesc>
                          Please enter your name as it appears on your passport,
                          drivers license, or government issued ID.
                        </SubHeaderDesc>
                        <SubHeaderDesc>
                          Please also enter your home address and phone number,
                          lastly please enter your social security number. None
                          of this information is stored and is encrypted and
                          securly sent.
                        </SubHeaderDesc>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          id="firstName"
                          value={this.state.firstName}
                          label="First Name"
                          type="text"
                          name="firstName"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                        <br />
                        <br />
                        <TextField
                          id="lastName"
                          value={this.state.lastName}
                          label="Last Name"
                          type="text"
                          name="lastName"
                          variant="outlined"
                          onChange={e => this.handleChange(e)}
                          style={{ width: "300px" }}
                        />
                        <br />
                        <br />
                        <InputMask
                          mask="(999) 999-9999"
                          value={this.state.phone}
                          id="phone"
                          onChange={e => this.handleChange(e)}
                        >
                          <TextField
                            label="Phone Number"
                            type="text"
                            name="phone"
                            variant="outlined"
                            style={{ width: "300px" }}
                          />
                        </InputMask>
                        <br />
                        <br />
                        <InputMask
                          mask="999-99-9999"
                          value={this.state.ssn}
                          id="ssn"
                          onChange={e => this.handleChange(e)}
                        >
                          <TextField
                            label="Social Security Number"
                            type="text"
                            name="ssn"
                            variant="outlined"
                            style={{ width: "300px" }}
                          />
                        </InputMask>
                        <br />
                        <br />
                        <ReactGoogleMapLoader
                          params={{
                            key: MY_API_KEY,
                            libraries: "places,geocode"
                          }}
                          render={googleMaps =>
                            googleMaps && (
                              <ReactGooglePlacesSuggest
                                googleMaps={googleMaps}
                                autocompletionRequest={{
                                  input: search
                                  // Optional options
                                  // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                                }}
                                // Optional props
                                onNoResult={this.handleNoResult}
                                onSelectSuggest={this.handleSelectSuggest}
                                onStatusUpdate={this.handleStatusUpdate}
                                textNoResults="Address Not Found"
                                customRender={prediction => (
                                  <MenuItem className="customWrapper">
                                    {prediction
                                      ? prediction.description
                                      : "Address Not Found"}
                                  </MenuItem>
                                )}
                              >
                                <TextField
                                  label="Home Address"
                                  variant="outlined"
                                  type="text"
                                  value={value}
                                  placeholder="Search a location"
                                  inputProps={{ style: { fontSize: 12 } }}
                                  onChange={this.handleInputChange}
                                  style={{ width: "230px", fontSize: "12px" }}
                                />
                                <TextField
                                  id="addressLine2"
                                  label="Unit #"
                                  variant="outlined"
                                  type="text"
                                  placeholder="Unit #"
                                  inputProps={{ style: { fontSize: 12 } }}
                                  value={this.state.addressLine2}
                                  onChange={e => this.handleChange(e)}
                                  style={{ width: "70px" }}
                                />
                              </ReactGooglePlacesSuggest>
                            )
                          }
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Button
                          disabled={this.state.disableContinue}
                          variant="contained"
                          color="primary"
                          size="large"
                          buttonstyle={{ borderRadius: 50 }}
                          style={{
                            borderRadius: 50,
                            textTransform: "none",
                            width: "300px"
                          }}
                          onClick={this.continue}
                        >
                          Save and Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default einStart;
